<template>
  <el-container class="page" v-if="load">
    <el-header>
      <div class="header">自定义打印模板</div>
      <div class="header-content">
        <el-button-group>
          <el-button type="primary" size="mini" @click="$router.go(-1)"> 返回 </el-button>
          <template>
            <el-button type="primary" size="mini" @click="saveTempalte()">
              保存模板
            </el-button>
            <el-button type="primary" size="mini" :disabled="!printTemplateId" @click="defalutTempalte()">
              设为默认
            </el-button>
            <!-- <el-button
              type="primary"
              size="mini"
              :disabled="!printTemplateId"
              click="delTempalte"
            >
              删除模板
            </el-button> -->
          </template>
        </el-button-group>
        <el-divider direction="vertical" />
        <template v-if="key === 'topCom' && pageConfig.topCom[activeIndex]">
          <SelectLocal v-model="pageConfig.topCom[activeIndex].fontFamily" style="width: 150px; margin-right: 10px"
            :option="{ data: fontData, value: 'value', label: 'label' }" />
          <SelectLocal v-model="pageConfig.topCom[activeIndex].fontSize" style="width: 80px; margin-right: 10px"
            :option="{ data: fontSizeData, value: 'value', label: 'label' }" />
          <el-tooltip class="item" effect="dark" content="粗体" placement="bottom">
            <div class="bold-view" :style="{
                marginRight: '10px',
                fontWeight: pageConfig.topCom[activeIndex].fontWeight,
                color: pageConfig.topCom[activeIndex].fontWeight ? '#409eff' : '',
              }" @click="setDivStyle('fontWeight', 'bold')">
              B
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="斜体" placement="bottom">
            <div class="bold-view" :style="{
                fontStyle: pageConfig.topCom[activeIndex].fontStyle,
                color: pageConfig.topCom[activeIndex].fontStyle ? '#409eff' : '',
              }" @click="setDivStyle('fontStyle', 'italic')">
              I
            </div>
          </el-tooltip>
        </template>
        <template v-else-if="key === 'bottomCom' && pageConfig.bottomCom[activeIndex]">
          <SelectLocal v-model="pageConfig.bottomCom[activeIndex].fontFamily" style="width: 150px; margin-right: 10px"
            :option="{ data: fontData, value: 'value', label: 'label' }" />
          <SelectLocal v-model="pageConfig.bottomCom[activeIndex].fontSize" style="width: 80px; margin-right: 10px"
            :option="{ data: fontSizeData, value: 'value', label: 'label' }" />
          <el-tooltip class="item" effect="dark" content="粗体" placement="bottom">
            <div class="bold-view" :style="{
                fontWeight: pageConfig.bottomCom[activeIndex].fontWeight,
                marginRight: '10px',
                color: pageConfig.bottomCom[activeIndex].fontWeight ? '#409eff' : '',
              }" @click="setDivStyle('fontWeight', 'bold')">
              B
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="斜体" placement="bottom">
            <div class="bold-view" :style="{
                fontStyle: pageConfig.bottomCom[activeIndex].fontStyle,
                color: pageConfig.bottomCom[activeIndex].fontStyle ? '#409eff' : '',
              }" @click="setDivStyle('fontStyle', 'italic')">
              I
            </div>
          </el-tooltip>
        </template>
        <template v-else>
          <SelectLocal v-model="fontFamily" style="width: 150px; margin-right: 10px"
            :option="{ data: fontData, value: 'value', label: 'label' }" />
          <SelectLocal v-model="fontSize" style="width: 80px; margin-right: 10px"
            :option="{ data: fontSizeData, value: 'value', label: 'label' }" />
          <el-tooltip class="item" effect="dark" content="粗体" placement="bottom">
            <div class="bold-view" style="margin-right: 10px">B</div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="斜体" placement="bottom">
            <div class="bold-view">I</div>
          </el-tooltip>
        </template>
        <el-divider direction="vertical" />
        <template>
          <SelectLocal v-model="pageConfig.headPrintType" style="width: 135px; margin-right: 10px"
            :option="{ data: headPrintData, value: 'value', label: 'label' }" />
          <SelectLocal v-model="pageConfig.endPrintType" style="width: 135px; margin-right: 10px"
            :option="{ data: endPrintData, value: 'value', label: 'label' }" />
        </template>
        <el-tooltip class="item" effect="dark" content="打印纸张设置" placement="bottom">
          <i class="el-icon-tickets" @click="openPageDialog()" />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="明细列表设置" placement="bottom">
          <i class="el-icon-s-grid" @click="openListDialog()" />
        </el-tooltip>
      </div>
    </el-header>
    <el-container class="content">
      <el-aside width="250px" class="aside">
        <leftView :pointer.sync="pointer" :data="defaultData" />
      </el-aside>
      <el-main>
        <!-- 主体内容 -->
        <section id="print-container" :style="{
            paddingLeft: `${getDpi(pageConfig.paddingLeft)}px`,
            paddingRight: `${getDpi(pageConfig.paddingRight)}px`,
            paddingTop: `${getDpi(pageConfig.paddingTop)}px`,
            paddingBottom: `${getDpi(pageConfig.paddingBottom)}px`,
            width: `${
              getDpi(pageConfig.width)
            }px`,
            height: `${
              getDpi(pageConfig.height)
            }px`,
          }" @click="onClick">
          <section v-if="pageConfig.topCom" @drop="(e) => drop(e, 'topCom')" @dragenter="(e) => dragenter(e, 'topCom')"
            @dragleave="(e) => dragleave(e, 'topCom')" @dragover.prevent :class="{
              'top-view': true,
              'topCom-view': true,
              bor: isEdit,
              'pointer-events': pagePointerEvents,
              'bor-out': isEdit && pointer.show,
              'bor-in': isEdit && (pointer.inType === 1 || key === 'topCom'),
            }" :style="{
              height: `${pageConfig.topStyle.height}px`,
            }">
            <template v-for="(item, index) in pageConfig.topCom">
              <TextBlock :key="index" v-model="item.name" v-drag="(e) => dragChange('topCom', index, e)" :class="[
                  'ewmBox',
                  `topCom-${index}`,
                  pointerEvents ? 'pointer-events' : '',
                  activeIndex === index && key === 'topCom' ? 'on-click' : '',
                ]" :style="item" :del="activeIndex === index && key === 'topCom'" :type="
                  !isEdit
                    ? 3
                    : item.common === 'pageInput' || item.value === 'input'
                    ? 2
                    : 1
                " @stopClick="selectCom('topCom', index)" @delClick="delCom('topCom', index)" />
            </template>
            <div v-dragTop:value="pageConfig" title="拖动调整大小" class="move_tz"></div>
          </section>
          <!-- 表格 -->
          <div v-if="showTable" :class="{
              bor: isEdit,
            }" :style="{
              margin: '8px 0px',
              marginRight: '2px',
              width: 'calc(100% - 1px)',
              height:
                [1, 3].includes(pageConfig.endPrintType) && !isEdit
                  ? 'auto'
                  : `${showTableHeight || tableHeihgt}px`,
            }">
            <TablePage v-if="options.list.length" ref="tablePage" v-model="options" @handleEvent="handleEvent" />
          </div>
          <!-- 底部编辑区域 -->
          <section v-if="(pageConfig.endPrintType !== 5 || isEdit) && pageConfig.bottomCom"
            @drop="(e) => drop(e, 'bottomCom')" @dragenter="(e) => dragenter(e, 'bottomCom')"
            @dragleave="(e) => dragleave(e, 'bottomCom')" @dragover.prevent :class="{
              'top-view': true,
              'bottomCom-view': true,
              bor: isEdit,
              'pointer-events': pagePointerEvents,
              'bor-out': isEdit && pointer.show,
              'bor-in': isEdit && (pointer.inType === 2 || key === 'bottomCom'),
            }" :style="{
              height: `${pageConfig.bottomStyle.height}px`,
            }">
            <template v-for="(item, index) in pageConfig.bottomCom">
              <TextBlock :key="index" v-model="item.name" v-drag="(e) => dragChange('bottomCom', index, e)" :class="[
                  'ewmBox',
                  `bottomCom-${index}`,
                  pointerEvents ? 'pointer-events' : '',
                  activeIndex === index && key === 'bottomCom' ? 'on-click' : '',
                ]" :style="item" :del="activeIndex === index && key === 'bottomCom'" :type="
                  !isEdit
                    ? 3
                    : item.common === 'pageInput' || item.value === 'input'
                    ? 2
                    : 1
                " @stopClick="selectCom('bottomCom', index)" @delClick="delCom('bottomCom', index)" />
            </template>
            <div v-dragBottom:value="pageConfig" title="拖动调整大小" class="move_tz_bottom"></div>
          </section>
        </section>
      </el-main>
    </el-container>
    <Dialog ref="Dialog" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </el-container>
</template>
<script>
import leftView from "@/views/system/custom/printTemplate/detail/left-view/index.vue";
import TextBlock from "@/views/system/custom/printTemplate/detail/text-block/index.vue";
import TablePage from "@/components/tablePage";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import Dialog from "@/components/Dialog";
import {
  getDetailTemplate,
  getMenuIdClass,
  updateTemplate,
  deleteTemplate,
  setDefaultTemplate,
  getMenuInfo,
} from "@/api/system/system/printTemplate";
import jsPDF from "jspdf";
import printJS from "print-js";
import { publicListAPI } from "@/api/system/maintain/param/publicParam";
import { deepCopy, objectHas } from "@/utils/index.js";
import Sortable from "sortablejs";

import domtoimage from "@/views/system/custom/printTemplate/detail/dom-to-image.js";
const getDpi = (num = 240) => {
  const inch = document.createElement("div");
  inch.style.width = "1in";
  // 将div的尺寸设置为1英寸后，它会自动根据设备的分辨率进行缩放
  document.body.appendChild(inch);
  const dpi = inch.offsetWidth;
  document.body.removeChild(inch);
  const unit = Number(num);
  const inchs = unit / 25.4; // 将毫米转换为英寸,cm就除与2.54以此类推
  const px = inchs * dpi; // 将英寸转换为像素
  return Math.round(px); // 四舍五入取整数像素值
};

export default {
  name: "printTemplateDetail",
  components: { leftView, TextBlock, SelectLocal, TablePage, Dialog },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
    printConfig: {
      type: Object,
      defalut: null,
    },
  },
  directives: {
    drag(el, bindings) {
      el.onmousedown = function (e) {
        var disy = e.pageY - el.offsetTop;
        var disx = e.pageX - el.offsetLeft;
        bindings.value({});
        document.onmousemove = function (e) {
          let moveX = e.pageX - disx;
          let moveY = e.pageY - disy;
          bindings.value({ left: moveX, top: moveY });
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
    dragTop(el, bindings) {
      el.onmousedown = function (e) {
        var disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          let moveY = e.pageY - disy;
          const endH =
            getDpi(bindings.value.height) - 24 - 32 - bindings.value.bottomStyle.height;
          if (moveY > endH) {
            moveY = endH;
          }
          bindings.value.topStyle.height = moveY;
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
    dragBottom(el, bindings) {
      el.onmousedown = function (e) {
        var disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          let moveY = bindings.value.bottomStyle.height - e.movementY;
          const endH =
            getDpi(bindings.value.height) - 24 - 32 - bindings.value.topStyle.height;
          if (moveY > endH) {
            moveY = endH;
          }
          if (moveY < 2) {
            moveY = 2;
          }
          bindings.value.bottomStyle.height = moveY;
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
  },
  computed: {
    tableHeihgt: {
      get() {
        const { paddingTop, paddingBottom } = this.pageConfig;
        return (
          getDpi(this.pageConfig.height) -
          this.pageConfig.topStyle.height -
          this.pageConfig.bottomStyle.height -
          getDpi(paddingTop) -
          getDpi(paddingBottom) -
          16
        );
      },
      set() { },
    },
    pointerEvents() {
      return this.pointer.show;
    },
    pagePointerEvents() {
      return this.dialogOptions.show;
    },
  },
  data() {
    return {
      showTable: true,
      showTableHeight: 0,
      load: false,
      defaultData: [],
      dialogOptions: {},
      options: {
        noShowColumnSet: true, //不获取列配置信息
      },
      fontData: [
        { label: "宋体", value: "Simsun" },
        { label: "黑体", value: "Simhei" },
        { label: "微软雅黑", value: "Microsoft YaHei" },
        { label: "楷体", value: "KaiTi" },
        { label: "Arial", value: "Arial" },
        { label: "Verdana", value: "Verdana" },
        { label: "Times New Roman", value: "Times New Roman" },
      ],
      fontSizeData: [
        { label: "12", value: "12px" },
        { label: "14", value: "14px" },
        { label: "16", value: "16px" },
        { label: "18", value: "18px" },
        { label: "20", value: "20px" },
        { label: "24", value: "24px" },
        { label: "28", value: "28px" },
        { label: "32", value: "32px" },
      ],
      headPrintData: [
        { label: "首页打印单据头", value: 1 },
        { label: "每页打印单据头", value: 2 },
      ],
      endPrintData: [
        { label: "只在最后一页打印（接明细）", value: 1 },
        { label: "只在最后一页打印（靠底部）", value: 2 },
        { label: "每页打印（接明细）", value: 3 },
        { label: "每页打印（靠底部）", value: 4 },
        { label: "不打印单据尾", value: 5 },
      ],
      fontFamily: "Microsoft YaHei",
      fontSize: "12px",
      getDpi: getDpi,
      pageConfig: {
        headPrintType: 1,
        endPrintType: 1,
        paperType: "A4", // 纸类型
        direction: 1, //打印方向
        width: 210, // 宽度 mm
        height: 297, // 高度 mm
        zIndex: 0,

        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,

        topStyle: {
          height: 150,
        },
        bottomStyle: {
          height: 80,
        },
        topCom: [],
        bottomCom: [],
        tableColumns: [],
        tableStyle: {
          fontFamily: "Microsoft YaHei",
          fontSize: "12px",
          fontWeight: "initial",
          height: "28px",
          lineHeight: "14px",
          maxHeight: "28px",
          minHeight: "28px",
          color: "black",
        },
      },
      activeIndex: -1,
      key: "",
      menuId: "",
      menuName: "",
      printTemplateId: "",
      pointer: {
        inType: 0,
        show: false,
        allBlock: {},
      },
      configPath: {
        "/aaaa": {
          dataKey: "data",
          goodsKey: "aaa",
          billIKey: "billIda",
          billIValueKey: "billIda",
        },
      },
    };
  },
  async mounted() {
    // 初始化页面
    try {
      // 获取模板详情
      this.printTemplateId =
        this.printConfig?.printTemplateId || this.$route.query.printTemplateId;
      let res = null;
      if (this.printTemplateId) {
        res = await getDetailTemplate({
          printTemplateId: this.printTemplateId,
        });
      } else {
        res = await getMenuInfo({ routerPath: this.$route.query.routerPath });
      }
      this.menuId = res.data.menuId;
      this.menuName = res.data.menuName;
      const config = await getMenuIdClass({ routerPath: res.data.routerPath });
      const defaultData = [];
      const columnsData = [];
      config.data = [
        {
          customText: "自定义文本",
          sort: 0,
          align: 1,
          isDefault: 0,
          name: "序号",
          minWidth: 20,
          value: "lineIndex",
          isDefaultCheck: 1,
        },
        ...config.data,
      ];
      config.data.forEach((x) => {
        // 0 false 1 true
        if (x.isDefault === 1) {
          defaultData.push(x);
        } else {
          columnsData.push(x);
        }
      });
      let data = {};
      try {
        data = JSON.parse(res.data.lableTemplateContent);
      } catch (error) { }
      this.pageConfig = { ...this.pageConfig, ...data };
      // 缓存的模板内容
      const pageData = [
        { value: "input", name: "输入框", common: "pageInput" },
        {
          value: "print_view_pager",
          name: "页码",
          common: "pageNums",
        },
        {
          value: "system_BUSINESS_COMMON_CONFIG_11",
          name: "公司名称",
          common: "information",
        },
        {
          value: "system_BUSINESS_COMMON_CONFIG_12",
          name: "公司地址",
          common: "information",
        },
        {
          value: "system_BUSINESS_COMMON_CONFIG_13",
          name: "公司电话",
          common: "information",
        },
        {
          value: "system_BUSINESS_COMMON_CONFIG_14",
          name: "公司传真",
          common: "information",
        },
        {
          value: "system_BUSINESS_COMMON_CONFIG_15",
          name: "公司邮编",
          common: "information",
        },
      ];
      this.defaultData = [...pageData, ...defaultData];
      this.setTabelColumn(columnsData);
      await this.$nextTick();
      this.load = true;
      if (this.printConfig) {
        this.printAll();
      }
      window.addEventListener("keydown", this.handleKeyDown);
      this.$nextTick(() => {
        setTimeout(() => {
          const width = this.getWidthFromString(table);
          console.log('table5', width); // 获取宽度
        }, 1000)
      });
    } catch (error) {
      console.log("???", error);
    }
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    getWidthFromString() {
      const table = this.$refs?.tablePage?.$refs?.table?.$refs?.mutipleTable?.$refs?.bodyWrapper?.innerHTML; // 获取 table DOM 元素
      const htmlString = table;
      // 正则匹配 width 数值（直接匹配数字部分）
      const match = htmlString.match(/class="el-table__body"[^>]*style="[^"]*width:\s*(\d+)px/);
      if (match && match[1]) {
        const widthNumber = parseInt(match[1], 10); // 转为数字
        console.log('纯数值:', widthNumber); // 输出 442
        return widthNumber;
      }
      return null;
    },
    showLoding() {
      this.loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
        customClass: "topLoading",
      });
    },
    hideLoading() {
      try {
        this.loading.close();
        this.loading = null;
      } catch (error) { }
    },
    // 打印全部
    async printAll() {
      if (this.loading) return false;
      this.showLoding();
      await this.$nextTick();
      try {
        let {
          width,
          height,
          topCom = [],
          bottomCom = [],
          topStyle,
          bottomStyle,
          tableStyle,
          endPrintType,
          headPrintType,
          direction,
          paperType,
        } = this.pageConfig; // 一些配置项目
        const { check, getDetailApi, lookPage } = this.printConfig;
        topCom = topCom.map(v => ({ ...v, color: 'black' }))
        bottomCom = bottomCom.map(v => ({ ...v, color: 'black' }))

        const topH = topStyle.height - 8; // 单据头高度
        const bottomH = bottomStyle.height - 8; // 单据尾 高度
        //l：横向  p：纵向
        //direction  1纵向  2横向
        const pdf = new jsPDF({
          unit: "mm",
          format: 'a4', // 初始格式（会被覆盖）
          orientation: 'portrait', // 初始方向（会被覆盖）
          putOnlyUsedFonts: true,
          compress: true,
          precision: 16,
        });
        // 手动设置页面尺寸
        pdf.internal.pageSize.setWidth(width);
        pdf.internal.pageSize.setHeight(height);
        let information = {}; // 公司信息
        const awaitTime = (t = 100) => new Promise((resolve) => setTimeout(resolve, t));
        const allCom = [...topCom, ...(endPrintType === 5 ? [] : bottomCom)]; // 所有的模板组件
        // 先循环请求完所有订单数据
        if (allCom?.find((x) => x.common === "information")) {
          try {
            const res = await publicListAPI();
            information = res.data;
          } catch (error) { }
        }
        // 获取所有需要打印的订单的明细
        const thHeight = document.querySelector(".el-table__header").offsetHeight;
        const lineHeight = Number(tableStyle.height.split("px")[0]) + 2; // 行高
        const res = await Promise.all(
          check.map(async (item, originIndex) => {
            const { billId } = item;
            await awaitTime();
            const { data } = await getDetailApi({
              billId,
              sortField: this.menuName != "仓库调出" ? "goodsNo" : "",
            });

            const printData = [];
            const detail = { ...data, ...information }; // 详情数据
            let tableIndex = 0;
            let detailData =
              detail.details || detail.detailItem || detail.billDetailItems || detail.billOrderListReserveDetailReqs; // 详情明细数据

            const allKeys = Object.keys(detailData[0]);
            // 筛选出包含 "Qty" 的键
            const qtyKeys = allKeys.filter((key) => key.includes("Qty"));
            // 筛选出包含 "Price" 的键
            const priceKeys = allKeys.filter((key) => key.includes("Price"));
            // 筛选出包含 "Money" 的键
            const moneyKeys = allKeys.filter((key) => key.includes("Money"));
            detailData = detailData.map((item) => {
              const newItem = {};

              qtyKeys.forEach((key) => {
                newItem[key] = this.$syInput(1, item[key]); // 将对应的值放入新对象中
              });

              priceKeys.forEach((key) => {
                newItem[key] = this.$syInput(2, item[key]);
              });

              moneyKeys.forEach((key) => {
                newItem[key] = this.$syInput(3, item[key]);
              });

              return {
                ...item,
                ...newItem, // 合并原始项和新项
              };
            });

            let isSplitPageObj = this.pageConfig.tableColumns.find(
              (x) => x.isSplitPage == 1
            );
            let isSplitPage = isSplitPageObj ? 1 : 0;
            let isSortObj = this.pageConfig.tableColumns.find((x) => x.isSort == 1);
            let isSort = isSortObj ? 1 : 0;
            if (isSplitPage == 1) {
              let arr = this.groupByUnitName(detailData, isSplitPageObj.prop);
              if (isSort == 1) {
                arr = await this.getIsSortArr(arr, isSortObj.prop);
              }
              const getPageInfo = (detailData2) => {
                // 默认需要一页
                let pageSize = ""; //
                let tableH = this.tableHeihgt - thHeight; // 表格能显示数据的高度
                let isNeedAddPage = false;
                // (首页打印单据头 && 不是第一页) || 当前页表格无数据
                if (
                  (headPrintType === 1 && tableIndex > 0) ||
                  (!detailData2.length && tableIndex !== 0 && headPrintType !== 2)
                ) {
                  this.pageConfig.topCom = null;
                  tableH = tableH + topH;
                } else {
                  this.pageConfig.topCom =
                    deepCopy(topCom)?.map?.((x) => ({
                      ...x,
                      name:
                        x.common === "pageInput" || x.value === "input"
                          ? x.name
                          : detail[x.value] || "",
                    })) || []; // 需要单据头
                }
                pageSize = Math.floor(tableH / lineHeight);
                if (
                  endPrintType === 5 ||
                  ([1, 2].includes(endPrintType) && detailData2.length > pageSize)
                ) {
                  this.pageConfig.bottomCom = null;
                  tableH = tableH + bottomH;
                  if (endPrintType !== 5 && detailData2?.length) {
                    isNeedAddPage = true;
                  }
                } else {
                  this.pageConfig.bottomCom =
                    deepCopy(bottomCom)?.map?.((x) => ({
                      ...x,
                      name:
                        x.common === "pageInput" || x.value === "input"
                          ? x.name
                          : detail[x.value] || (detail[x.value] == undefined ? '' : detail[x.value]) || '',
                    })) || [];
                }
                const maxPageSize = Math.floor(tableH / lineHeight);

                const dataList = detailData2.slice(0, maxPageSize);
                detailData2 = detailData2.slice(maxPageSize);
                printData.push(
                  deepCopy({
                    dataList, // 当前页数据
                    tableIndex, // 当前页
                    bottomCom: this.pageConfig.bottomCom,
                    topCom: this.pageConfig.topCom,
                    showTableHeight: tableH + thHeight,
                    showTable: Boolean(dataList.length || tableIndex === 0),
                    pageSize,
                    maxPageSize,
                  })
                );
                if (
                  detailData2.length
                    ? true
                    : isNeedAddPage && !detailData2.length && dataList.length
                ) {
                  tableIndex = tableIndex + 1;
                  getPageInfo(detailData2);
                }
              };
              for (let i = 0; i < arr.length; i++) {
                let detailData2 = arr[i];
                getPageInfo(detailData2);
              }
            } else {
              if (isSort == 1) {
                detailData = this.getIsSort(detailData, isSortObj.prop);
              }
              const getPageInfo = () => {
                // 默认需要一页
                let pageSize = ""; //
                let tableH = this.tableHeihgt - thHeight; // 表格能显示数据的高度
                let isNeedAddPage = false;
                // (首页打印单据头 && 不是第一页) || 当前页表格无数据
                if (
                  (headPrintType === 1 && tableIndex > 0) ||
                  (!detailData.length && tableIndex !== 0 && headPrintType !== 2)
                ) {
                  this.pageConfig.topCom = null;
                  tableH = tableH + topH;
                } else {
                  this.pageConfig.topCom =
                    deepCopy(topCom)?.map?.((x) => ({
                      ...x,
                      name:
                        x.common === "pageInput" || x.value === "input"
                          ? x.name
                          : detail[x.value] || "",
                    })) || []; // 需要单据头
                }
                pageSize = Math.floor(tableH / lineHeight);
                if (
                  endPrintType === 5 ||
                  ([1, 2].includes(endPrintType) && detailData.length > pageSize)
                ) {
                  this.pageConfig.bottomCom = null;
                  tableH = tableH + bottomH;
                  if (endPrintType !== 5 && detailData?.length) {
                    isNeedAddPage = true;
                  }
                } else {
                  this.pageConfig.bottomCom =
                    deepCopy(bottomCom)?.map?.((x) => ({
                      ...x,
                      name:
                        x.common === "pageInput" || x.value === "input"
                          ? x.name
                          : detail[x.value] || (detail[x.value] == undefined ? '' : detail[x.value]) || '',
                    })) || [];
                }

                const maxPageSize = Math.floor(tableH / lineHeight);
                const dataList = detailData.slice(0, maxPageSize);
                detailData = detailData.slice(maxPageSize);
                printData.push(
                  deepCopy({
                    dataList, // 当前页数据
                    tableIndex, // 当前页
                    bottomCom: this.pageConfig.bottomCom,
                    topCom: this.pageConfig.topCom,
                    showTableHeight: tableH + thHeight,
                    showTable: Boolean(dataList.length || tableIndex === 0),
                    pageSize,
                    maxPageSize,
                  })
                );
                if (
                  detailData.length
                    ? true
                    : isNeedAddPage && !detailData.length && dataList.length
                ) {
                  tableIndex = tableIndex + 1;
                  getPageInfo();
                }
              };
              getPageInfo();
            }
            return { originIndex, /** originInfo: item, */ printData };
          })
        );

        const data = res.sort((a, b) => a.originIndex - b.originIndex);
        for (let i = 0; i < data.length; i++) {
          let lineIndex = 0;
          for (let index = 0; index < data[i].printData.length; index++) {
            const item = data[i].printData[index];
            this.showTable = item.showTable;
            this.showTableHeight = item.showTableHeight;
            const getPager = (inData) =>
              inData?.map?.((x) => ({
                ...x,
                name:
                  x.common === "pageNums"
                    ? `第 ${index + 1} 页 / 共 ${data[i].printData.length} 页`
                    : x.name,
              })) || inData;
            this.pageConfig.topCom = getPager(item.topCom);
            this.pageConfig.bottomCom = getPager(item.bottomCom);
            if (index === data[i].printData.length - 1 || ((index === data[i]?.printData?.length - 2) && (data[i]?.printData?.[index + 1]?.dataList?.length == 0))) {
              let printSummaryData = [];
              data[i].printData.forEach((z) => {
                printSummaryData = [...printSummaryData, ...z.dataList];
              });
              this.options.printSummaryData = printSummaryData;
              this.options.hideSummary = false;
            } else {
              this.options.printSummaryData = undefined;
              this.options.hideSummary = true;
            }
            this.options.list =
              item.dataList?.map?.((x, idx) => ({
                ...x,
                lineIndex: lineIndex + idx + 1,
              })) || [];
            lineIndex = lineIndex + item.dataList.length;
            await this.$nextTick();
            let element = document.getElementById("print-container");
            let width2 = element.offsetWidth;
            let height2 = element.offsetHeight;
            let imgData = await domtoimage.toJpeg(element, {
              height: height2,
              width: width2,
              scale: 4,
              style: { margin: 0, color: "black", background: "#ffffff" },
            });
            if (!imgData || !imgData.length) {
              this.hideLoading();
              return this.$message.error("打印失败：生产打印内容失败");
            }
            if ((i === 0 && index > 0) || i > 0) {
              pdf.addPage();
              // 显式设置新页面的尺寸
              pdf.internal.pageSize.setWidth(width);
              pdf.internal.pageSize.setHeight(height);
            }
            pdf.addImage(imgData, "JPEG", 0, 0, width, height);
          }
        }
        const printable = pdf.output("bloburl");
        if (lookPage) {
          window.open(printable, "_blank");
        } else {
          printJS({
            printable, // 获取 Blob 对象的临时路径
            type: "pdf",
            onPrintDialogClose(...e) {
              console.log("onPrintDialogClose", ...e);
            },
          });
        }
      } catch (error) {
        console.log("printAll error", error);
      }
      this.hideLoading();
    },
    groupByUnitName(array, prop) {
      const grouped = {};
      array.forEach((item) => {
        const keyName = item[prop];
        // 如果 grouped 中还没有这个 keyName，则初始化一个空数组
        if (!grouped[keyName]) {
          grouped[keyName] = [];
        }
        // 将当前项添加到对应的 keyName 数组中
        grouped[keyName].push(item);
      });
      // 获取映射表中所有的数组作为结果，并返回这些数组的数组形式
      return Object.values(grouped);
    },
    getIsSort(array, prop) {
      return array.sort((obj1, obj2) => {
        const val1 = obj1[prop];
        const val2 = obj2[prop];

        // 检查两个值是否都是数字
        const isNum1 = typeof val1 === "number";
        const isNum2 = typeof val2 === "number";

        if (isNum1 && isNum2) {
          // 如果两个值都是数字，则直接相减进行数值比较
          return val1 - val2;
        } else if (!isNum1 && !isNum2) {
          console.log("什么错", val2);
          // 如果两个值都是字符串，则使用localeCompare进行比较
          return val1.localeCompare(val2, "zh-CN");
        } else {
          // 如果一个是数字另一个是字符串，则根据需要将它们分开
          // 这里假设数字应该排在字符串前面
          return isNum1 ? -1 : 1;
        }
      });
    },
    getIsSortArr(array, prop) {
      return array.map((x) => this.getIsSort(x, prop));
    },
    handleKeyDown(e) {
      try {
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          e.preventDefault();
          const [oldTop] = this.pageConfig[this.key][this.activeIndex].top.split("px");
          const top = Number(oldTop) + (e.key === "ArrowDown" ? 1 : -1);
          this.dragChange(this.key, this.activeIndex, { top });
        } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
          e.preventDefault();
          const [oldLeft] = this.pageConfig[this.key][this.activeIndex].left.split("px");
          const left = Number(oldLeft) + (e.key === "ArrowRight" ? 1 : -1);
          this.dragChange(this.key, this.activeIndex, { left });
        }
      } catch (error) { }
    },
    async setTabelColumn(data) {
      this.pageConfig.tableColumns =
        this.pageConfig?.tableColumns
          ?.sort?.((a, b) => Number(a.sort) - Number(b.sort))
          ?.map?.((x, sort) => ({ ...x, sort })) || [];
      await this.$nextTick();
      const tableColumns = []; // 表格列
      data.forEach((x, i) => {
        let item = this.pageConfig.tableColumns?.find?.(
          (y) => y.prop === x.value || y.prop === x.prop
        );
        const aligns = { 0: "left", 1: "center", 2: "right" };
        const prop = x.prop || x.value;
        const label = item?.label || x.name || x.label;
        const sort = objectHas(item, "sort") ? item?.sort : x.sort;
        const minWidth = objectHas(item, "minWidth")
          ? item?.minWidth
          : x.columnWidth || x.minWidth;
        const align = item?.align || aligns[x.align] || aligns["1"];
        const isDefaultCheck = objectHas(item, "isDefaultCheck")
          ? item?.isDefaultCheck
          : x.isDefaultCheck;
        tableColumns.push({
          ...x,
          prop,
          label,
          minWidth,
          align,
          isDefaultCheck,
          sort,
          name: x.name || x.label || label,
          summary: item?.summary === 1 || x?.summary === 1 ? 1 : 0,
          width: item?.width || undefined,
          isSplitPage: item?.isSplitPage === 1 || x?.isSplitPage === 1 ? 1 : 0,
          isSort: item?.isSort === 1 || x?.isSort === 1 ? 1 : 0,
        });
      });
      this.pageConfig.tableColumns = tableColumns.sort(
        (a, b) => Number(a.sort) - Number(b.sort)
      );
      const columns = this.pageConfig.tableColumns?.filter((x) => x.isDefaultCheck === 1);
      const headerDragend = (w, oldw, { property } = {}) => {
        const table = this.$refs.tablePage.$refs.table.$refs.mutipleTable;
        table.columns.forEach((x) => {
          if (x.level === 1) {
            const i = this.pageConfig.tableColumns.findIndex(
              (y) => x.property === y.prop
            );
            console.log("iiii", i, this.pageConfig.tableColumns[i]);
            if (i >= 0) {
              this.pageConfig.tableColumns[i].width = x.width;
            }
          } else if (x.level === 2) {
            const i = this.pageConfig.tableColumns.findIndex(
              (y) => y.children?.length && y.children.find((z) => z.prop === x.property)
            );
            if (i >= 0) {
              const ii = this.pageConfig.tableColumns[i].children.findIndex(
                (y) => y.prop === x.property
              );
              if (ii >= 0) {
                this.pageConfig.tableColumns[i].children[ii].width = x.width;
              }
            }
          }
        });
      };
      this.options = {
        list: [{}],
        hideCard: true,
        cellStyle: this.pageConfig.tableStyle,
        height: "auto",
        noShowColumnSet: true, //不获取列配置信息
        summary: columns.filter((x) => x.summary === 1).map((x) => x.prop),
        headerDragend,
        hidePagination: true,
        columns,
      };
      await this.$nextTick();
    },
    async saveTempalte() {
      try {
        if (this.printTemplateId) {
          await updateTemplate({
            printTemplateId: this.printTemplateId,
            lableTemplateContent: JSON.stringify(this.pageConfig),
          });
          this.$message.success("保存成功");
        } else {
          this.openSaveDialog();
        }
      } catch (error) { }
    },
    async defalutTempalte() {
      if (!this.printTemplateId) return;
      try {
        await setDefaultTemplate({
          printTemplateId: this.printTemplateId,
          menuId: this.menuId,
        });
        this.$message.success("设置成功");
      } catch (error) { }
    },
    async delTempalte() {
      if (!this.printTemplateId) return;
      try {
        await deleteTemplate([this.printTemplateId]);
        this.$message.success("删除成功");
        setTimeout(() => {
          this.$router.back();
          this.$destroy();
        }, 2000);
      } catch (error) { }
    },
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          if (row.type === "PrintTemplateSetting") {
            this.pageConfig = deepCopy({ ...this.pageConfig, ...row.formData });
            this.setTabelColumn(this.pageConfig.tableColumns);
          } else if (row.type === "PrintTemplateUpdate") {
            this.pageConfig.tableColumns = deepCopy(row.data.list).sort(
              (a, b) => a.sort - b.sort
            );
            const { height, fontSize } = row.formData;
            const h = Number(height.split("px")[0]);
            const s = Number(fontSize.split("px")[0]);
            this.pageConfig.tableStyle = deepCopy({
              ...row.formData,
              lineHeight: `${Math.floor(h / Math.floor(h / s))}px`,
              maxHeight: height,
              minHeight: height,
            });
            this.setTabelColumn(this.pageConfig.tableColumns);
          } else if (row.type === "PrintTemplate") {
            this.printTemplateId = row?.formData?.formApiRes?.data || "";
          }
        default:
          break;
      }
    },
    /**
     * 当在有效放置目标上放置元素或选择文本时触发此事件
     * @param {Object} event event对象
     */
    drop(event, key = "") {
      /* 在范围内松开鼠标 */
      event.preventDefault();
      // const rect = event.target.getBoundingClientRect(); // 获取目标元素的定位信息
      let data = event.dataTransfer.getData("component");
      if (data) {
        this.pageConfig.zIndex = this.pageConfig.zIndex + 1;
        const obj = {
          position: "absolute",
          left: `${event.offsetX}px`,
          top: `${event.offsetY}px`,
          zIndex: this.pageConfig.zIndex,
          fontFamily: this.fontFamily,
          fontSize: "12px",
          color: "black",
          ...JSON.parse(data),
        };
        this.pageConfig[key].push(obj);
      }
      this.pointer.inType = 0;
      this.key = "";
    },
    dragenter(event, key) {
      /* 进入 */
      event.preventDefault();
      this.pointer.show = true;
      this.pointer.inType = key === "topCom" ? 1 : 2;
      this.key = key;
      console.log(event, "----------dragenter 进入", key);
    },
    dragleave(event, key) {
      /* 离开 */
      event.preventDefault();
      if (this.key === key) {
        this.pointer.inType = 0;
        this.key = "";
        console.log(event, "----------dragenter 离开", key);
      }
    },
    onClick() {
      this.activeIndex = -1;
      this.key = "";
    },
    dragChange(key, index, obj = {}) {
      const style = obj;
      const div = document.querySelector(`.${key}-${index}`).getBoundingClientRect();
      const view = document.querySelector(`.${key}-view`).getBoundingClientRect();
      if (Object.prototype.hasOwnProperty.call(style, "left")) {
        try {
          let maxLeft = view.width - div.width;
          if (style.left > maxLeft) {
            style.left = maxLeft;
          }
          if (style.left < 0) {
            style.left = 0;
          }
        } catch (error) { }
        style.left = style.left + "px";
      }
      if (Object.prototype.hasOwnProperty.call(style, "top")) {
        try {
          let maxTop = view.height - div.height;
          if (style.top > maxTop) {
            style.top = maxTop;
          }
          if (style.top < 0) {
            style.top = 0;
          }
        } catch (error) { }
        style.top = style.top + "px";
      }
      this.$set(this.pageConfig[key], index, {
        ...this.pageConfig[key][index],
        ...style,
      });
    },
    selectCom(key, index) {
      this.pageConfig.zIndex = this.pageConfig.zIndex + 1;
      this.activeIndex = index;
      this.key = key;
      this.pageConfig[key][this.activeIndex].zIndex = this.pageConfig.zIndex;
    },
    delCom(key, index) {
      this.activeIndex = -1;
      this.key = "";
      this.pageConfig[key].splice(index, 1);
    },
    setDivStyle(key, val) {
      this.$set(
        this.pageConfig[this.key][this.activeIndex],
        key,
        this.pageConfig[this.key][this.activeIndex][key] === val ? "" : val
      );
    },
    openSaveDialog() {
      this.dialogOptions = {
        show: true,
        title: "新增打印模板",
        click: "add",
        width: 550,
        type: "PrintTemplate",
        formData: {
          isStop: false,
          menuId: this.menuId,
          menuName: this.menuName,
          lableTemplateContent: JSON.stringify(this.pageConfig),
        },
      };
    },
    openListDialog() {
      // 定义基础列配置
      const columns = [
        {
          type: "my-checkbox",
          prop: "isDefaultCheck",
          label: "打印",
          minWidth: 60,
        },
        {
          prop: "name",
          label: "默认文本",
          minWidth: 120,
        },
        {
          type: "my-input",
          prop: "label",
          label: "自定义文本",
          minWidth: 120,
        },
        {
          type: "my-checkbox",
          prop: "summary",
          label: "是否合计",
          minWidth: 80,
        },
        {
          type: "my-checkbox",
          prop: "isSplitPage",
          label: "是否分页",
          minWidth: 80,
        },
        {
          type: "my-checkbox",
          prop: "isSort",
          label: "行排序",
          minWidth: 60,
        },
        {
          type: "icons",
          elIcon: "el-icon-rank",
          elStyle: {
            fontSize: "22px",
          },
          prop: "sort",
          label: "列排序",
          hideTooltip: true,
          minWidth: 80,
        },
        {
          prop: "minWidth",
          label: "列宽",
          minWidth: 80,
          formatter: (v, row) => row.width || v,
        },
        {
          type: "my-select-local",
          prop: "align",
          label: "对齐方式",
          minWidth: 120,
          option: {
            value: "value",
            label: "label",
            data: [
              { value: "left", label: "左对齐" },
              { value: "center", label: "居中对齐" },
              { value: "right", label: "右对齐" },
            ],
          },
        }
      ];
      // 定义基础配置
      this.dialogOptions = {
        show: true,
        title: "列表明细设置",
        width: 860,
        type: "PrintTemplateUpdate",
        formData: deepCopy(this.pageConfig.tableStyle),
        data: {
          list: deepCopy(this.pageConfig.tableColumns),
          hideCard: true,
          height: 500,
          hidePagination: true,
          noShowColumnSet: true, //不获取列配置信息
          rowKey: "prop",
          tableId: "printDialogTable",
          columns,
        },
      };
      setTimeout(() => {
        this.$nextTick(() => {
          const el = document.querySelector(
            "#printDialogTable .el-table__body-wrapper tbody"
          );
          new Sortable(el, {
            animation: 150,
            handle: ".el-icon-rank",
            ghostClass: "blue-background-class",
            onEnd: async (evt) => {
              // 移除当前项
              let list = deepCopy(this.dialogOptions.data.list);
              const currRow = list.splice(evt.oldIndex, 1)[0];
              // 根据移动方向更新sort值
              if (evt.newIndex > evt.oldIndex) {
                // 下移操作，更新被挤压的元素的sort值
                for (let i = evt.oldIndex; i < evt.newIndex; i++) {
                  list[i].sort--;
                }
              } else {
                // 上移操作，更新被挤压的元素的sort值
                for (let i = evt.oldIndex - 1; i >= evt.newIndex; i--) {
                  list[i].sort++;
                }
              }
              // 在新位置插入当前项，并设置其sort值
              currRow.sort = evt.newIndex;
              list.splice(evt.newIndex, 0, currRow);
              this.$set(this.dialogOptions.data, "list", list)
              // 重新布局表格（如果需要）
              this.$refs.Dialog.$refs.myDialog.$refs.tablePage.$refs.table.$refs.mutipleTable.doLayout();
            },
          });
        });
      }, 500);
    },
    openPageDialog() {
      this.dialogOptions = {
        show: true,
        title: "打印纸张设置",
        width: 760,
        type: "PrintTemplateSetting",
        formData: deepCopy(this.pageConfig),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  ::v-deep #table-page {
    height: auto !important;
  }
  .el-header {
    padding: 0;
    .header {
      height: 25px;
      line-height: 25px;
      font-size: 14px;
      padding: 0 10px;
      font-weight: bold;
      background-color: #f7f8f9;
    }
    .header-content {
      display: flex;
      padding: 0 10px;
      align-items: center;
      height: 35px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      .bold-view {
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: #f4f5f6;
        }
      }
      i {
        margin-right: 8px;
        cursor: pointer;
        width: 28px;
        height: 28px;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        &:hover {
          background-color: #f4f5f6;
        }
      }
    }
  }

  .content {
    height: calc(100vh - 60px);
    .aside {
      background-color: #ffffff;
    }
  }

  .el-main {
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f4f5f6;

    .bor {
      box-shadow: 0 0px 2px #444444, 0 0 0px #444444;
      border-radius: 3px;
      overflow: hidden;
    }

    .bor-out {
      box-shadow: 0 0px 4px red, 0 0 0px red !important;
    }

    .bor-in {
      box-shadow: 0 0px 4px green, 0 0 0px green !important;
    }

    .pointer-events {
      pointer-events: none;
    }

    #print-container {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      background: #ffffff;
      margin: 10px auto;
      position: relative;
      overflow: hidden;
      -webkit-appearance: none;
      appearance: none;
      .top-view {
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: #ffffff;
        background: #ffffff;
      }
    }
    .on-click {
      border: 1px solid #409eff;
    }
  }
  .move_tz {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 3px;

    z-index: 99999;
    cursor: ns-resize;
    background-image: url("");
    background-position: 0px 0px;
  }
  .move_tz_bottom {
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    height: 3px;

    z-index: 99999;
    cursor: ns-resize;
    background-image: url("");
    background-position: 0px 0px;
  }
  ::v-deep .el-card {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    .cardContent {
      padding: 0 !important;
    }
    .el-table {
      &::before,
      .el-table__fixed::before {
        height: 0px;
      }
      .el-table__header-wrapper th,
      .el-table__fixed-header-wrapper th {
        background: #ffffff;
        color: #000000;
      }
      th.el-table__cell,
      td.el-table__cell {
        border-top: 2px solid #000000;
        border-left: 2px solid #000000;
        background-color: #ffffff;
        color: #000000;
      }
      .el-table__header,
      .el-table__body,
      .el-table__footer {
        border-right: 2px solid #000000;
      }
      // .el-table__header-wrapper,
      // .el-table__body-wrapper,
      // .el-table__footer-wrapper {
      //   border-right: 2px solid #000000;
      // }
      .el-table__footer {
        border-bottom: 2px solid #000000;
      }
      /* 关键样式 */
      .no-scroll-table {
        table-layout: fixed; /* 固定表格布局 */
      }

      /* 单元格内容截断 */
      .truncate-cell {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: clip; /* 直接裁剪不显示省略号 */
      }

      /* 彻底隐藏横向滚动条 */
      .el-table__body-wrapper {
        overflow-x: hidden !important;
        scrollbar-width: none; /* Firefox */
      }
      .el-table__body-wrapper::-webkit-scrollbar {
        display: none; /* Chrome/Safari */
      }

      /* 确保表头也同步隐藏滚动条 */
      .el-table__header-wrapper {
        overflow-x: hidden !important;
      }
      td.is-leaf {
        // border-top: 2px solid #000000;
        color: #000000;
      }
    }
    .el-table--group,
    .el-table--border {
      // border-right: 0 solid #000000;
      // border: 2px solid #000000;
      // border-bottom: 0 solid #000000;
      // border-right: 0 solid #000000;
    }
    td.el-table__cell {
      padding: 0 !important;
      .cell {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        height: inherit;
        line-height: inherit;
        max-height: inherit;
        min-height: inherit;
        padding: 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        span {
          max-height: inherit;
          width: 100%;
        }
      }
    }

    .table-header.el-table__cell {
      .cell {
        min-width: 100% !important;
        display: block !important;
      }
    }
  }
  ::v-deep .el-table__row {
    .cell {
      height: 20px;
      overflow: hidden;
      line-height: 20px;
      padding: 0;
    }
  }
}
</style>
