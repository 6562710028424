<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <cardTitleCom cardTitle="编码规则">
      <template slot="cardContent">
        <div class="tableContent">
          <div class="tableBtn">
            <div>
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                >新增
              </el-button>

              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
                >删除
              </el-button>
            </div>

            <div>
              <el-button
                class="marR10"
                icon="el-icon-refresh"
                size="mini"
                @click="refresh($event)"
                >刷新
              </el-button>
            </div>
          </div>

          <el-table
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            border
            row-key="lineId"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :height="tableHeight"
            :max-height="tableHeight"
            ref="multipleTable"
            @select="select"
            @select-all="selectAll"
            @selection-change="handleSelectionChange"
            :select-on-indeterminate="true"
            :default-sort="{ prop: 'sortNo', order: 'ascending' }"
            @sort-change="handleSortChange"
          >
            <el-table-column align="center" type="selection" width="55">
            </el-table-column>
            <el-table-column
              label="单据名称"
              show-overflow-tooltip
              prop="billName"
              width="250"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                  target="_blank"
                >
                  {{ scope.row.billName }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="billType"
              label="单据编号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              label="编码规则"
              show-overflow-tooltip
              prop="codeRuleTable"
            >
            </el-table-column>
            <el-table-column
              prop="codeRuleDate"
              label="编码日期"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column label="编码方式">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.coding_rules_encoding_method"
                  :value="scope.row.codeMode"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="codeRulePrefix"
              label="编码前缀"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              width="100"
              prop="guideNoLength"
              label="流水号位数"
              show-overflow-tooltip
            />

            <el-table-column
              width="100"
              prop="sortNo"
              label="排序"
              show-overflow-tooltip
              :sortable="true"
            />
          </el-table>
        </div>
      </template>
    </cardTitleCom>
    <!-- 添加或修改编码规则 -->
    <c-dialog :title="title" :width="1000" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="codeForm" :rules="rules" label-width="90px">
          <!-- 编码规则第一层 -->

          <el-form-item label="上级目录" prop="deptId" key="deptId">
            <treeselect
              :max-depth="1"
              class="treeselectWidth"
              :class="{ codeTreeselect: isActive === true }"
              v-model="codeForm.parentId"
              :options="treeData"
              :show-count="true"
              placeholder="请选择上级目录"
              :normalizer="normalizer"
              :disabled="disabledUp"
              filterable
              clearable
            />
          </el-form-item>

          <!-- 编码规则第二层 -->
          <div class="x-f marT15">
            <el-form-item
              label="单据名称"
              prop="billName"
              key="billName"
              v-if="disabledTwo"
            >
              <el-input
                v-model="codeForm.billName"
                placeholder="请输入单据名称"
                maxlength="8"
              />
            </el-form-item>
            <el-form-item
              label="单据名称"
              prop="billName"
              key="billName"
              v-else
            >
              <el-select
                v-model="codeForm.billName"
                placeholder="请选择单据名称"
                clearable
                @change="codeChange(dict.type.bill_type)"
                filterable
              >
                <el-option
                  v-for="dict in dict.type.bill_type"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="单据编号"
              prop="billType"
              key="billType"
              v-if="disabled || disabledTwo"
            >
              <el-input
                v-model="codeForm.billType"
                placeholder="请输入数值类型的编号"
                maxlength="18"
              />
            </el-form-item>
            <el-form-item
              label="单据编号"
              prop="billType"
              key="billType"
              v-else
            >
              <el-input
                :disabled="true"
                v-model="codeForm.billType"
                placeholder="单据编号"
              />
            </el-form-item>
            <el-form-item label="规则排序" prop="sortNo" key="sortNo">
              <el-input-number
                v-model="codeForm.sortNo"
                controls-position="right"
                :min="1"
              ></el-input-number>
            </el-form-item>
          </div>
          <!-- 编码规则第三层 -->
          <div class="x-f marT15" v-if="!disabled && !disabledTwo">
            <el-form-item label="前缀类型" prop="codeRulePrefix">
              <div class="x-f">
                <el-select
                  placeholder=""
                  class="prefixTypeWidth"
                  :disabled="disabled"
                  v-model="codeForm.codeRulePrefixType"
                >
                  <el-option label="常量" :value="0"></el-option>
                  <el-option label="固定字段" :value="1"></el-option>
                </el-select>
                <el-input
                  maxlength="6"
                  class="prefixWidth"
                  v-model="codeForm.codeRulePrefix"
                  :placeholder="
                    codeForm.codeRulePrefixType === 1
                      ? '固定字段6位'
                      : '编码前缀'
                  "
                  :disabled="disabled || codeForm.codeRulePrefixType === 1"
                />
              </div>
            </el-form-item>
            <el-form-item label="日期">
              <el-select
                class="dateWidth"
                :disabled="disabled"
                v-model="codeForm.codeRuleDate"
                placeholder="编码日期"
                filterable
                clearable
              >
                <el-option label="yyyyMMdd" value="yyyyMMdd"></el-option>
                <el-option label="yyyyMM" value="yyyyMM"></el-option>
                <el-option label="MMdd" value="MMdd"></el-option>
                <el-option label="yy" value="yy"></el-option>
                <el-option label="yyyy" value="yyyy"></el-option>
                <el-option label="yymm" value="yymm"></el-option>
                <el-option label="yymmdd" value="yymmdd"></el-option>
                <el-option label="mmyy" value="mmyy"></el-option>
                <el-option label="(空)" value=""></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="流水位数"
              prop="guideNoLength"
              key="guideNoLength"
            >
              <el-input-number
                ref="refNumber"
                class="inputNumber"
                :disabled="disabled"
                v-model="codeForm.guideNoLength"
                controls-position="right"
                :min="2"
                :max="guideNoLength"
              >
                >
              </el-input-number>
            </el-form-item>
          </div>
          <!-- 编码规则第四层 -->
          <div class="x-f marT15" v-if="!disabled && !disabledTwo">
            <el-form-item label="编码方式" prop="codeMode" key="codeMode">
              <el-select
                :disabled="disabled"
                v-model="codeForm.codeMode"
                placeholder="请选择编码方式"
              >
                <el-option
                  v-for="dict in dict.type.coding_rules_encoding_method"
                  :key="dict.value"
                  :label="dict.label"
                  :value="parseInt(dict.value)"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="编码规则" key="codeRule">
              <el-input
                :disabled="true"
                v-model="codeRule"
                placeholder="编码规则"
              />
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  listCodeRule,
  addcodeRule,
  getCodeRuleDetail,
  updatecodeRule,
  delcodeRule,
} from "@/api/codeRule.js";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
export default {
  name: "codeRule",
  dicts: ["coding_rules_encoding_method", "bill_type"],
  components: { cardTitleCom, Treeselect, CDialog },
  data() {
    return {
      tableTwo: [], //判断是不是二级使用
      isActive: false,
      codeRuleTable: "", //表格编码规则
      codeRule: "", //编码规则
      disabledUp: false, //上级目录禁用
      disabled: true, //表单禁用
      loading: false, //表格加载框
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 弹出层标题
      title: "",
      // 是否显示弹窗
      open: false,
      //表单弹框
      codeForm: {
        sortNo: 1,
        guideNoLength: null,
        parentId: null, //上级目录
        billName: null, //单据类型
        billType: null, //单据编号
        codeRulePrefix: null, //编码前缀
        codeRuleDate: null, //编码日期
        codeMode: null, //编码方式
        codeRulePrefixType: 0, //前缀类型
      },
      tableData: [], //table
      treeData: [], //tree
      //弹框校验
      rules: {
        billName: [
          {
            required: true,
            message: "单据名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        billType: [
          {
            required: true,
            message: "单据编号不能为空",
            trigger: ["blur", "change"],
          },
          { pattern: /^[0-9]{1,6}$/, message: "请输入1~6位的数值类型" },
        ],
        codeMode: [
          {
            required: true,
            message: "编码方式不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      multipleSelection: [],
      name: [], //选中数组的名称
      ids: [], // 选中数组
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 1000000,
        parentId: null, //上级目录
        billName: null, //单据类型
        billType: null, //单据编号
        codeRulePrefix: null, //编码前缀
        codeRuleDate: null, //编码日期
        codeMode: null, //编码方式
      },
      //日期枚举
      dateType: {
        yyyyMMdd: "YYYYMMDD",
        yyyyMM: "YYYYMM",
        MMdd: "MMDD",
        yy: "YY",
        yyyy: "YYYY",
        yymm: "YYMM",
        yymmdd: "YYYYMMDD",
        mmyy: "MMYY",
        "": "",
      },
      vivwH: 0, //页面高度
    };
  },
  created() {
    this.getList();
  },
  computed: {
    tableHeight() {
      return this.vivwH - 120;
    },
    disabledTwo() {
      return (
        this.codeForm.parentId == 0 ||
        this.tableTwo.some(
          (item) => this.codeForm.parentId == item.lineId && item.parentId == 0
        )
      );
    },
    guideNoLength() {
      let codeRulePrefix = 0;
      if (this.codeForm.codeRulePrefixType === 1) {
        codeRulePrefix = 6;
      } else {
        codeRulePrefix = this.codeForm.codeRulePrefix
          ? this.codeForm.codeRulePrefix.length
          : 0;
      }
      return (
        20 -
        (codeRulePrefix +
          (this.codeForm.codeRuleDate ? this.codeForm.codeRuleDate.length : 0))
      );
    },
    totalLength() {
      let codeRulePrefix = 0;
      if (this.codeForm.codeRulePrefixType === 1) {
        // codeRulePrefix = 6
      } else {
        codeRulePrefix = this.codeForm.codeRulePrefix
          ? this.codeForm.codeRulePrefix.length
          : 0;
      }
      return (
        (this.codeForm.guideNoLength
          ? Number(this.codeForm.guideNoLength)
          : 0) +
        codeRulePrefix +
        (this.codeForm.codeRuleDate ? this.codeForm.codeRuleDate.length : 0)
      );
    },
  },
  watch: {
    //侦听日期
    "codeForm.codeRuleDate": {
      handler(newVal, oldValue) {
        if (this.totalLength > 20) {
          this.$message({
            message: "编码规则最多20位",
            type: "warning",
          });
          this.codeForm.codeRuleDate = oldValue;
        }
        // this.codeForm.codeRuleDate
      },
      immediate: true,
    },
    //侦听常量的编码前缀
    "codeForm.codeRulePrefix": {
      handler(newVal, oldValue) {
        if (this.totalLength > 20) {
          this.$message({
            message: "编码规则最多20位",
            type: "warning",
          });
          this.codeForm.codeRulePrefix = oldValue;
        }
      },
      immediate: true,
    },
    //侦听常量的编码前缀类型
    "codeForm.codeRulePrefixType": {
      handler(newVal, oldValue) {
        // console.log('totalLength', this.totalLength)
        if (this.codeForm.codeRulePrefixType === 1) {
          //固定字段
          this.codeForm.codeRulePrefix = undefined;
          this.codeForm.guideNoLength = 2;
        } else if (this.codeForm.codeRulePrefixType === 0) {
          //常量
          this.codeForm.guideNoLength = 5;
          if (!this.codeForm.codeRulePrefix) {
            this.codeForm.codeRulePrefix = "NO";
          }
        }
        if (
          this.codeForm.codeRulePrefixType === 1
            ? this.totalLength + 6 > 20
            : this.totalLength > 20
        ) {
          this.$message({
            message: "编码规则最多20位",
            type: "warning",
          });
          this.codeForm.codeRulePrefixType = oldValue;
        }
      },
      immediate: true,
    },

    codeForm: {
      handler: function (val, oldVal) {
        if (this.codeForm.parentId === 0) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
        if (
          this.codeForm.codeRulePrefix ||
          this.codeForm.codeRuleDate ||
          this.codeForm.guideNoLength
        ) {
          let num = "";
          const N = "N";
          for (let index = 0; index < this.codeForm.guideNoLength; index++) {
            num += N;
          }
          if (this.codeForm.codeRulePrefixType == 0) {
            this.codeRule = `(${this.codeForm.codeRulePrefix})${
              this.dateType[this.codeForm.codeRuleDate]
            }${num}`;
          } else if (this.codeForm.codeRulePrefixType == 1) {
            this.codeRule = `(固定字段)${
              this.dateType[this.codeForm.codeRuleDate || ""]
            }${num}`;
          }
        }

        if (
          val.parentId &&
          val.parentId != 0 &&
          this.tableTwo.some(
            (item) => val.parentId == item.lineId && item.parentId != 0
          )
        ) {
          if (this.disabledUp) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
        } else {
          this.codeForm.codeRulePrefix = "";
          this.codeForm.codeRuleDate = "";
          this.codeForm.codeMode = -1;
          this.codeForm.guideNoLength = 0;
          this.disabled = true;
          if (!val.parentId) {
            this.codeForm.parentId = 0;
          }
        }
      },
      deep: true,
    },
    disabled(val) {
      if (!this.disabled) {
        if (!this.codeForm.lineId) {
          this.codeForm.codeRulePrefix = "NO";
          this.codeForm.codeRuleDate = "yyyyMMdd";
          // this.codeForm.guideNoLength = 5
          this.codeForm.codeMode = 0;
          this.$set(this.codeForm, "guideNoLength", "5");
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
    },
    //筛选是不是二级
    getTwoScreen(id) {
      // this.
    },
    // 假设shopTreeOptions是表示可选择选项的对象数组
    // 用于限制选项级别的函数
    limitLevels(options, level = 1) {
      if (level > 1) {
        options.forEach((option) => {
          delete option.children;
        });
        return;
      }
      options.forEach((option) => {
        if (option.children && option.children.length > 0) {
          this.limitLevels(option.children, level + 1);
        }
      });
    },
    //排序   树形表格中的所有层级进行排序，可以使用递归的方式对每个子节点进行排序
    handleSortChange({ prop, order }) {
      this.sortTree(this.tableData, prop, order);
    },
    sortTree(data, prop, order) {
      data.sort((a, b) => {
        const aValue = this.getPropValue(a, prop);
        const bValue = this.getPropValue(b, prop);
        if (order === "ascending") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.sortTree(item.children, prop, order);
        }
      });
    },
    getPropValue(obj, prop) {
      const props = prop.split(".");
      let value = obj;
      for (let i = 0; i < props.length; i++) {
        value = value[props[i]];
      }
      return value;
    },
    //对话框关闭前的回调
    handleClose(done) {
      if (this.codeForm) {
        this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {});
      } else {
        done();
      }
    },
    codeChange(row) {
      const res = row.filter((v) => v.label === this.codeForm.billName);
      if (res.length >= 1) {
        this.codeForm.billType = res[0].value;
      } else {
        this.codeForm.billType = null;
      }
    },
    /** 查询分页列表 */
    async getList() {
      this.loading = true;
      await listCodeRule(this.queryParams).then((response) => {
        response.rows.forEach((v) => {
          if (v.parentId === 0) {
            return;
          }
          let num = "";
          const N = "N";
          for (let index = 0; index < v.guideNoLength; index++) {
            num += N;
          }
          if (v.codeRulePrefix) {
            v.codeRuleTable = `(${v.codeRulePrefix})${v.codeRuleDate}${num}`;
          } else {
            v.codeRuleTable = `${v.codeRuleDate}${num}`;
          }
          
        });
        // 第一种树形方法
        // const newArr = [];
        // const map = {};
        // response.rows.forEach((item) => {
        //   if (!item.children) item.children = []; //判断数据是否有子级   没有则进行子级的添加
        //   map[item.lineId] = item; // 添加对应的映射关系
        // });
        // response.rows.forEach((item) => {
        //   if (map[item.parentId]) {
        //     map[item.parentId].children.push(item);
        //   } else {
        //     newArr.push(item);
        //   }
        // });
        // this.tableData = newArr;
        //第二种树形方法,两种方法都可以
        //筛选二级或三级
        this.tableTwo = JSON.parse(JSON.stringify(response.rows));

        //表格
        this.tableData = this.handleTree(response.rows, "lineId");
        //排序
        this.sortTree(this.tableData, "sortNo", "ascending");
        //树状
        this.treeData = JSON.parse(JSON.stringify(this.tableData));
        const obj = {
          billName: "请选择上级目录(一级)",
          lineId: 0,
        };
        this.treeData.unshift(obj);
        // 在将选项传递给treeselect组件之前调用limitLevels函数
        this.limitLevels(this.treeData);
      });

      this.loading = false;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$modal
        .confirm('是否确认删除编码规则名称为"' + this.name + '"的数据项？')
        .then(() => {
          return delcodeRule(this.ids);
        })
        .then(() => {
          this.$modal.msgSuccess("删除成功");
          this.getList();
        })
        .catch(() => {});
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.disabledUp = false;
      this.reset();
      this.title = "新增编码规则";
      this.open = true;
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.codeForm.lineId != undefined) {
            updatecodeRule(this.codeForm).then((response) => {
              this.$modal.msgSuccess("修改编码规则成功");
              this.open = false;
              this.getList();
            });
          } else {
            addcodeRule(this.codeForm).then((response) => {
              this.$modal.msgSuccess("新增编码规则成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.lineId,
        label: node.billName,
        children: node.children,
      };
    },
    // 表单重置
    reset() {
      this.codeForm = {
        sortNo: 1,
        guideNoLength: null,
        parentId: null, //上级目录
        billName: null, //单据类型
        billType: null, //单据编号
        codeRulePrefix: null, //编码前缀
        codeRuleDate: null, //编码日期
        codeMode: null, //编码方式
        codeRulePrefixType: 0, //前缀类型
      };
      this.codeRule = "";
      this.resetForm("form");
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.lineId);
      this.name = selection.map((item) => item.billName);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    setChildren(children, type) {
      // 编辑多个子层级
      children.map((j) => {
        this.toggleSelection(j, type);
        if (j.children) {
          this.setChildren(j.children, type);
        }
      });
    },
    // 选中父节点时，子节点一起选中取消
    select(selection, row) {
      const hasSelect = selection.some((el) => {
        return row.lineId === el.lineId;
      });
      if (hasSelect) {
        if (row.children) {
          // 解决子组件没有被勾选到
          this.setChildren(row.children, true);
        }
      } else {
        if (row.children) {
          this.setChildren(row.children, false);
        }
      }
    },
    toggleSelection(row, select) {
      if (row) {
        this.$nextTick(() => {
          this.$refs.multipleTable &&
            this.$refs.multipleTable.toggleRowSelection(row, select);
        });
      }
    },
    // 选择全部
    selectAll(selection) {
      // tabledata第一层只要有在selection里面就是全选
      const isSelect = selection.some((el) => {
        const tableDataIds = this.tableData.map((j) => j.lineId);
        return tableDataIds.includes(el.lineId);
      });
      // tableDate第一层只要有不在selection里面就是全不选
      const isCancel = !this.tableData.every((el) => {
        const selectIds = selection.map((j) => j.lineId);
        return selectIds.includes(el.lineId);
      });
      if (isSelect) {
        selection.map((el) => {
          if (el.children) {
            // 解决子组件没有被勾选到
            this.setChildren(el.children, true);
          }
        });
      }
      if (isCancel) {
        this.tableData.map((el) => {
          if (el.children) {
            // 解决子组件没有被勾选到
            this.setChildren(el.children, false);
          }
        });
      }
    },
    //  修改\点击编号
    async handleDetail(row) {
      this.reset();
      getCodeRuleDetail(row.lineId).then((response) => {
        this.codeForm = response.data;
        this.title = "编辑编码规则";
        if (row.parentId === 0) {
          this.disabledUp = true;
          // this.codeForm.guideNoLength = undefined
        } else {
          this.disabledUp = false;
        }
        this.open = true;
      });
    },
    //刷新按钮
    refresh(e) {
      this.queryParams = {
        pageNum: 1,
        pageSize: 1000000,
        parentId: null, //上级目录
        billName: null, //单据类型
        billType: null, //单据编号
        codeRulePrefix: null, //编码前缀
        codeRuleDate: null, //编码日期
        codeMode: null, //编码方式
      };

      this.getList();

      let target = e.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = e.target.parentNode;
      }
      target.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .tableContent {
    padding: 6px 10px;

    .tableBtn {
      height: 50px;
      line-height: 50px;
      background-color: #f9f9fa;
      padding-left: 5px;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.codeTreeselect {
  ::v-deep .vue-treeselect__single-value {
    color: #cccccc;
  }
}

::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}

//上级目录
.treeselectWidth {
  width: 205px;
}

//编码前缀类型
.prefixTypeWidth {
  width: 110px;
}

//编码前缀
.prefixWidth {
  width: 113px;
}

//日期
.dateWidth {
  width: 210px;
}
</style>
